import {
  Box3,
  Group,
  DoubleSide,
  Color,
  PlaneGeometry,
  MeshBasicMaterial,
  Mesh,
  sRGBEncoding,
  PlaneBufferGeometry,
  Vector3,
  RepeatWrapping,
  BoxGeometry,
  CubeCamera,
  WebGLCubeRenderTarget,
  LinearMipmapLinearFilter,
  RGBFormat,
  MultiplyOperation,
  CameraHelper,
  IcosahedronGeometry,
  MeshLambertMaterial,
  WebGLRenderTarget,
  PerspectiveCamera,
  MeshPhongMaterial, DirectionalLight, DirectionalLightHelper,
} from 'three';

import {
  createBaseMaterial,
  createBillboardMaterial,
  createMaterials,
  prepareTexture,
  createOverlayBaseMaterial,
  setupOverlayMaterial, setupButtonMaterial, createShadowMaterial, createBaseBasicMaterial,
} from './materials.js';
import { Billboard } from '../Billboard/Billboard';
import { Spiderman } from '../People/Spiderman';
import { Stacy } from 'src/Parker/Renderables/People/Stacy';

import { gsap } from 'gsap';
import {AGENDA, RECEPTION, COMMUNITY, DISPLAYS, WELCOME, state, isAnimating, PRE_RENDER_LAYER} from '../../World.js'

import {Reflector} from 'src/Parker/vendor/Reflector';

import {
  dispatchOnRoomActivate,
  dispatchOnRoomExit,
  dispatchOnRoomEnter, dispatchOnCategoryChange, dispatchOnDisplaysChange,
} from '../../../constants/event';
import {VIDEO} from "src/constants/rooms";


const itemScale = 0.0254;
const BLOOM_SCENE = 1;

class Booth extends Group {
  constructor(assets, customProperties) {
    super();

    this.customProperties = customProperties;
    this.billboards = [];
    this.updatables = [];
    this.bloomedObjects = [];
    this.name = 'Booth';
    this.config = assets.config.booth;
    this.setupModels(assets);
    this.setupLights();
  }

  setupModels(assets) {
    const booth = assets.models['booth'].scene;

    this.add(booth);
    this.setupBillboards(assets);
    this.setupPeople(assets);
    const geometry = new PlaneGeometry(6, 1);
    const material = new MeshBasicMaterial({ color: 0xffffff, side: DoubleSide });
    const plane = new Mesh(geometry, material);
    plane.position.y = 3;
    plane.position.z = 5;
    plane.name = 'TEST';
    // this.add( plane );

    this.setupMaterials(assets);
    this.setupShadows();
    //this.scale.x = 0.0254;
    //this.scale.y = 0.0254;
    //this.scale.z = 0.0254;
  }

  setupBillboards(assets) {
    const billboard1 = new Billboard(assets, 'billboard_panel_1', this.customProperties?.billboard_1_category, 'displays-left');
    const billboard2 = new Billboard(assets, 'billboard_panel_2', this.customProperties?.billboard_2_category, 'displays-left-back');
    const billboard3 = new Billboard(assets, 'billboard_panel_3', this.customProperties?.billboard_3_category, 'displays-right-back');
    const billboard4 = new Billboard(assets, 'billboard_panel_4', this.customProperties?.billboard_4_category, 'displays-right');

    const billboard1Position = this.getChildByName('display_1_position');
    if (!billboard1Position) return;
    billboard1.position.copy(billboard1Position.position);
    billboard1.rotation.copy(billboard1Position.rotation);
    const billboard2Position = this.getChildByName('display_2_position');
    billboard2.position.copy(billboard2Position.position);
    billboard2.rotation.copy(billboard2Position.rotation);
    const billboard3Position = this.getChildByName('display_3_position');
    billboard3.position.copy(billboard3Position.position);
    billboard3.rotation.copy(billboard3Position.rotation);
    const billboard4Position = this.getChildByName('display_4_position');
    billboard4.position.copy(billboard4Position.position);
    billboard4.rotation.copy(billboard4Position.rotation);

    this.add(billboard1);
    this.add(billboard2);
    this.add(billboard3);
    this.add(billboard4);
    this.billboards.push(billboard1);
    this.billboards.push(billboard2);
    this.billboards.push(billboard3);
    this.billboards.push(billboard4);
  }

  setupPositionTransparent(item) {
    item.material.transparent = true;
    item.material.opacity = 0;
  }

  setupPeople(assets) {
    return;
    const stacy = new Stacy(assets, 'stacy');
    //this.add(stacy);
    //this.updatables.push(stacy);

    const stacyPosition = this.getChildByName('reception_person_position');
    stacy.position.copy(stacyPosition.position);
  }

  setupCopyPLanes(initialObject) {
    const aux = new Box3().setFromObject(initialObject);
    const multiplier = 1;
    const plane = new Mesh(
      new PlaneBufferGeometry(aux.getSize().x * multiplier, aux.getSize().z * multiplier, 100, 100),
    );
    plane.receiveShadow = false;
    plane.position.copy(initialObject.position);
    //plane.rotation.set(0, 0, Math.PI / 2);
    plane.rotation.copy(initialObject.rotation);
    plane.name = initialObject.name + '_plane';
    initialObject.parent.add(plane);
    initialObject.visible = false;
    return plane;
  }

  setupMaterials(assets) {
    this.materials = createMaterials();
    const setupTarget = (item, type = 'item', targetListener = onItemClick) => {
      let target = '';
      if (item.name.includes('agenda')) {
        target = AGENDA;
      } else if (item.name.includes('team') || item.name.includes('community')) {
        target = COMMUNITY;
      } else if (item.name.includes('reception')) {
        target = RECEPTION;
      } else {
        target = DISPLAYS/*`${DISPLAYS}__${item.parent?.targetCategory}`*/;
      }
      if (target !== '') {
        if(!state[target]) {
          state[target] = {
            camera: item.parent.targetCamera
          }
        }
        state[target][type] = item;
        item.target = target;
        if(target == DISPLAYS) {
          if(!state[target].items[item.parent?.targetCategory]) {
            state[target].items[item.parent?.targetCategory] = {
              selected: false
            };
          }
          state[target].items[item.parent?.targetCategory].camera = item.parent.targetCamera;
        }
        /*item.onClick = () => {
          targetListener(item);
        };*/
      } else {
        console.log("TEST", item)
      }
    }

    const onItemHover = (item, skipDisplays = false) => {
      const opacity = 0.6;
      if(state[item.target].selected) return;
      if(state[item.target].item) {
        state[item.target].item.children.forEach((item) => {
          let targetOpacity = opacity;
          if(item.name.includes("text")) {
            targetOpacity = 1;
          }
          gsap.killTweensOf(item.material);
          gsap.to(item.material, { opacity: targetOpacity, duration: 0.4, onComplete: () => {
            gsap.to(item.material, { opacity: 0, duration: 0.2})
          }});
        });
      }

      if(item.target.split("__")[0] === DISPLAYS) {
        this.billboards.forEach((item) => {
          item.onItemHover(item);
        })
      }
    }

    const onItemClick = (item) => {
      if(isAnimating) {
        return;
      }
      if(item.parent?.name.includes('billboard')) {
        dispatchOnDisplaysChange({ category: item.parent.targetCategory });
        gtag('event', 'click-display', {
          event_category: 'homepage',
          event_label: `${item.parent.targetCategory}`,
        });
      } else if (item.name == 'poster_1') {
        dispatchOnRoomEnter({room: VIDEO})
      } else {
        if((item.name.includes('overlay') && state[item.target]?.selected) ||
          (item.name.includes('button') && !state[item.target]?.selected)) {
          return;
        }
        if(item.target === RECEPTION && item.name.includes('button')) {
          window.chaport.on('ready', function () {
            window.chaport.open();
          })
        } else {
          dispatchOnRoomEnter({room:item.target});
        }
      }
    };

    this.traverse((child) => {
      if (child.name.includes('glass_1')) {
        child.material = this.materials.glass;
      } else if (child.name === 'booth_ground') {
        child.material.side = DoubleSide;
      } else if (child.name.includes('led')) {
        child.layers.enable(BLOOM_SCENE);
        child.userData.bloom = true;
      } else if (child.name.includes('overlay')) {
        setupOverlayMaterial(child);
        if (child.target == -1) {
          setupTarget(child, 'item');
          child.onHover = () => { onItemHover(child) };
          child.onClick = () => { onItemClick(child) };
        }
        child.transparent = true;
      } else if (child.name.includes('button') || child.name === 'poster_1') {
        setupButtonMaterial(child);
        if (child.target == -1) {
          setupTarget(child, 'button');
          child.onClick = () => {
            onItemClick(child)
          };
        }
        child.transparent = true;
      }
    });

    this.config.materials.envs.forEach((item) => {
      this.applyMapToChild({
        ...item,
        child: this.getObjectByName(item.childName),
        texture: assets.textures[item.texture],
      });
    }, this);

    this.config.materials.base.forEach((item) => {
      this.applyMapToChild({
        ...item,
        child: this.getObjectByName(item.childName),
        texture: assets.textures[item.texture],
      });
    }, this);

    this.config.materials.billboards.forEach((item) => {
      let child = this.getObjectByName(item.childName);
      if (child) {
        this.applyMapToChild({
          ...item,
          child: child.getObjectByName('billboard_1'),
          texture: assets.textures[item.texture],
        });
      }
    }, this);
    //this.applyMapToChild(this.getObjectByName("TEST"), assets.textures.booth_banner_top, true)
    this.createMirrorMaterial();
  }

  createMirrorMaterial() {
    this.getObjectByName('floor_1').material.emissive = new Color(0xc0b0b)
    return;
    this.cubeRenderTarget = new WebGLCubeRenderTarget( 256, {
      format: RGBFormat,
      generateMipmaps: true,
      minFilter: LinearMipmapLinearFilter,
      encoding: sRGBEncoding // temporary -- to prevent the material's shader from recompiling every frame
    });

    this.cubeCamera = new CubeCamera( 0.1, 20, this.cubeRenderTarget );
    window.camera = this.cubeCamera;
    this.reflectionMaterial = new MeshPhongMaterial( {
      envMap: this.cubeRenderTarget.texture,
      color: 0xCCCCCC,
      specular: 0x000000,
    });

    this.getObjectByName('floor_1').material = this.reflectionMaterial;
    this.cubeCamera.position.copy(this.getObjectByName('mirror_camera').position);

    this.sphere = new Mesh( new IcosahedronGeometry( 1, 8 ), this.reflectionMaterial );
    this.add(this.cubeCamera);
  }

  applyMapToChild({ child, texture, material, fit = false, color, copy, measures }) {
    if (child) {
      if (copy) {
        child = this.setupCopyPLanes(child);
      }
      switch (material) {
        case 'billboard':
          child.material = createBillboardMaterial();
          break;
        case 'base':
          child.material = createBaseMaterial();
          break;
        case 'baseBasic':
          child.material = createBaseBasicMaterial();
          break;
        case 'baseClone':
          let auxMaterial = child.material;
          child.material = createBaseMaterial();
          break;
        case 'extra':
          child.material = createShadowMaterial();
          break;
        case 'extra_button':
          child.material = createShadowMaterial();
          child.material.depthWrite = false;
          child.material.depthTest = true;
          child.material.opacity = 0;
          child.transparent = true;
          break;
      }
      if (fit) {
        if (!measures) {
          measures = { width: 'x', height: 'y' };
        }
        /*let size = new Box3().setFromObject(child).getSize();
        const width = size[measures.width];
        const height = size[measures.height];*/
        prepareTexture(texture, 0, 0);
        child.material.map = texture;

      } else if (texture) {
        texture.encoding = sRGBEncoding;
        texture.flipY = false;
        //texture.wrapS = RepeatWrapping;
        //texture.repeat.x = -1;
        child.material.map = texture;
      }
      if (color) {
        child.material.color.set(color);
      }
    }
  }

  getBoundingBox() {
    if (!this.bbox) {
      this.bbox = new Box3().setFromObject(this);
    }
    return this.bbox;
  }

  setupShadows() {
    this.traverse((child) => {
      child.castShadow = false;
      child.receiveShadow = false;
    });
    this.getObjectByName('booth_ground').receiveShadow = false;
    //this.getObjectByName('stacy').castShadow = true;
  }

  setupLights() {
    const lightIntensity = 0.1;
    const light1 = new DirectionalLight(
      new Color(0xffffff).convertSRGBToLinear(),
      lightIntensity,
    );
    light1.position.copy(this.getObjectByName('reception_light_1').position);
    light1.rotation.copy(this.getObjectByName('reception_light_1').rotation);
    const light2 = new DirectionalLight(
      new Color(0xffffff).convertSRGBToLinear(),
      lightIntensity,
    )
    light2.position.copy(this.getObjectByName('reception_light_2').position);
    light2.rotation.copy(this.getObjectByName('reception_light_2').rotation);
    this.add(light1);
    this.add(light2);

  }

  update(delta) {
    this.updatables.forEach((updatable) => updatable.update(delta));

  }

  prerender(renderer, scene) {
    if(this.cubeCamera) {
      this.cubeCamera.update(renderer, scene);
    }
  }


  loopThroughObjects({targetProperty, opacity, duration, opacityChecker, onComplete}) {
    Object.keys(state).forEach((key) => {
      if (state[key][targetProperty]) {
        state[key][targetProperty].children.forEach((item) => {
          let targetOpacity = opacity;
          if(item.name.includes("text") || item.name.includes('texture')) {
            targetOpacity = 1;
          }
          gsap.to(item.material, { opacity: opacityChecker(state[key]) ? targetOpacity : 0, duration, onComplete: onComplete});
        });
      }
    }, this);
  }

  onStateChange() {
    this.loopThroughObjects({
      targetProperty:'button',
      opacity:1,
      duration: 1,
      opacityChecker:({selected}) => {
        return selected
      }})
    /*this.loopThroughObjects({
      targetProperty:'item',
      opacity:0.6,
      duration: 0.1,
      opacityChecker:({isHover}) => {
        return isHover
      },
      onComplete: (state) => {
        state.isHover = false;
      })*/
    this.billboards.forEach(billboard => {
      billboard.onStateChange();
    });
  }
}

export { Booth };
