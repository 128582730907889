import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import { useStyles } from './CategoryCard.style';
import { useDataContext } from 'src/context/DataContext';

export function CategoryCard({ id, slug, name, image, slides, className, swiperReady, active, ...props }) {
  const rootRef = useRef();
  const [size, setSize] = useState();
  const [isImageLoaded, setImageLoaded] = useState(false);
  const { setProduct } = useDataContext();

  const onResize = useCallback(() => {
    try {
      setSize({ width: rootRef.current.offsetWidth, height: rootRef.current.offsetHeight });
    } catch (e) {
      // Do nothing
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize, true);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);
  useEffect(onResize, [swiperReady]);

  useLayoutEffect(() => {
    if (!isImageLoaded) return;

    gsap.fromTo(rootRef.current, { opacity: 0 }, { opacity: 1 });
  }, [isImageLoaded]);

  const classes = useStyles({ size });
  const fullId = `carousel-3d-card-${id}`;

  return (
    <div
      ref={rootRef}
      id={fullId}
      className={clsx(classes.root, className, {
        [classes.inactive]: !active,
      })}
    >
      <div className={classes.content}>
        <div className={classes.background}>
          <img src={image} alt={{}} className={clsx(classes.image)} onLoad={() => setImageLoaded(true)} />
          <img src="/assets/pad.png" alt={{}} className={clsx(classes.padImage)} />
        </div>
      </div>

      <div className={clsx(classes.slides, classes.slidesThumbnails)}>
        {slides.map(({ id, image, title, captionImage, ...product }, index) => {
          if (index > 2) return null;

          return (
            <div
              key={`${id}-${index.toString()}`}
              className={classes.slide}
              onClick={() => {
                if (!slug || !product.slug) return;

                gtag('event', 'click-solution', {
                  event_category: 'category-carousel',
                  event_label: `${product.slug}`,
                });
                setProduct({ category: slug, product: product.slug });
              }}
            >
              {image && <img src={image} alt={title} className={classes.slideImage} />}
              {captionImage && (
                <img src={captionImage} alt={title} className={clsx(classes.slideTitle, classes.slideTitleImage)} />
              )}
              {!captionImage && (
                <Typography component="span" variant="body2" className={classes.slideTitle} color="secondary">
                  {title}
                </Typography>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
