import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        pointerEvents: 'none',
      },
      background: {
        background: theme.palette.background.semitransparent,
        transition: theme.transitions.create('background-color', { easing: 'linear' }),
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
      },
      content: {
        height: '30.9375rem',
        width: '38.75rem',
        backgroundColor: 'white',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        padding: '5.625rem 6rem ',
        textAlign: 'center',
        '--spacing-vertical': '3.4375rem',
        pointerEvents: 'auto',
      },
      title: {
        fontFamily: theme.typography.fontFamilies.dinot,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '2.5rem',
        lineHeight: 1.1,
        letterSpacing: -0.03,
        marginBottom: 'var(--spacing-vertical)',
      },
      text: {
        fontFamily: theme.typography.fontFamilies.dinot,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '1.375rem',
        lineHeight: 1.54,
        letterSpacing: -0.03,
        marginBottom: 'var(--spacing-vertical)',
      },
    };
  },
  { name: 'RegisterModal' },
);
