import CameraControls from '../vendor/yomotsu/CameraControls.js';

function setupControls(camera, container) {
  const controls = new CameraControls(camera, container);

  controls.maxPolarAngle = Math.PI * 0.5;
  controls.minPolarAngle = Math.PI * 0.45;
  controls.maxAzimuthAngle = Math.PI / 4;
  controls.minAzimuthAngle = - Math.PI / 4;

  controls.minDistance = 7;
  controls.maxDistance = 30;

  controls.truckSpeed = 2.0;
  controls.enabled = true;

  return controls;
}

export { setupControls };
