import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'black',
      },
      model: {},
      bottomButton: {
        position: 'absolute',
        bottom: '1.7rem',
        zIndex: 999,
      },
      buttonChat: {
        right: '1.7rem',
      },
      logoContainer: {
        '--width': '100vw',
        background: 'black',
        position: 'absolute',
        pointerEvents: 'none',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        flexDirection: 'column',
      },
      logo: {
        width: 'var(--width)',
      },
      logoBarContainer: {
        display: 'none',
        width: '100%',
        height: '0.5%',
        width: 'var(--width)',
        marginTop: "1%"
      },
      logoBar: {
        display: 'none',
        width: '100px',
        height: '100%',
        backgroundColor: theme.palette.secondary.main,
      },
    };
  },
  { name: 'Booth' },
);
