import { useState } from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { ButtonClose } from '../ButtonClose';
import { Logo } from '../Logo';

import { useStyles } from './Product.style';
import { useStyles as useLinkStyles } from 'src/components/Link';
import { useDataContext } from 'src/context/DataContext';
import { IframeContainer } from '../IframeContainer';
import Markdown from 'markdown-to-jsx';

export function Product({
  categorySlug,
  slug,
  headerImage,
  leftImage,
  rightImage,
  title,
  text,
  links = [],
  onClose,
  caption,
}) {
  const classes = useStyles();
  const linkClasses = useLinkStyles();
  const { setShowProductIframe, setIframeData } = useDataContext();

  function onClick(link) {
    // const label = `talks__${selectedCategorySlug
    //   .split('-')
    //   .map((s) => s[0])
    //   .join('')}__${slug}`;

    gtag('event', 'click-button1', {
      event_category: 'product-crousel',
      event_label: `talks__${slug}`,
    });

    if (link.target != '_iframe') return;

    setIframeData(link);
    setShowProductIframe(true);
  }

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        {headerImage && <img src={headerImage} className={classes.logo} />}
        <ButtonClose className={classes.close} onClick={() => onClose()} />
      </div>
      <div className={classes.container}>
        <div className={clsx(classes.column, classes.leftColumn)}>
          <figure className={classes.figure}>
            <img src={leftImage} alt={{}} className={clsx(classes.image)} />
            {caption && (
              <Typography variant="p" component="figcaption" className={classes.leftColumnCaption}>
                {caption}
              </Typography>
            )}
          </figure>
        </div>

        <div className={clsx(classes.column, classes.rightColumn)}>
          {rightImage && <img src={rightImage} alt={{}} className={clsx(classes.image, classes.coverImage)} />}
          {!rightImage && <div className={classes.gradientBackground} />}
          <div className={classes.content}>
            <div className={classes.textContainer}>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
              <Markdown
                options={{
                  overrides: {
                    p: {
                      component: Typography,
                      props: {
                        variant: 'body1',
                        className: classes.text,
                      },
                    },
                    a: {
                      component: Typography,
                      props: {
                        component: 'a',
                        variant: 'body1',
                        className: classes.text,
                        target: '_blank',
                      },
                    },
                    li: {
                      component: Typography,
                      props: {
                        component: 'li',
                        variant: 'body1',
                        className: classes.text,
                      },
                    },
                  },
                }}
              >
                {text}
              </Markdown>
            </div>

            <div className={classes.linkContainer}>
              {links.map(({ id, url, text, enabled, ...props }, index) => {
                return (
                  <Link
                    key={id.toString()}
                    className={linkClasses.link}
                    href={url}
                    disabled={!enabled}
                    onClick={() => onClick(links[index])}
                    {...props}
                  >
                    {text}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
