/**
 * Data
 */
export const ON_DATA_LOADING = 'ON_DATA_LOADING';
export const ON_DATA_LOADED = 'ON_DATA_LOADED';
export const ON_DATA_ERROR = 'ON_DATA_ERROR';

export function dispatchOnDataLoading({ loading } = {}) {
  if (!loading) return;
  document.dispatchEvent(new CustomEvent(ON_DATA_LOADING, { detail: { loading } }));
}
export function dispatchOnDataLoaded({ data } = {}) {
  if (!data) return;
  document.dispatchEvent(new CustomEvent(ON_DATA_LOADED, { detail: { data } }));
}
export function dispatchOnDataError({ error } = {}) {
  if (!error) return;
  document.dispatchEvent(new CustomEvent(ON_DATA_ERROR, { detail: { error } }));
}

/**
 * Room
 */
export const ON_ROOM_CHANGE = 'ON_ROOM_CHANGE';
export const ON_ROOM_EXIT = 'ON_ROOM_EXIT';
export const ON_ROOM_ENTER = 'ON_ROOM_ENTER';
export const ON_ROOM_ACTIVATE = 'ON_ROOM_ACTIVATE';

export function dispatchOnRoomChange({ id, room } = {}) {
  if (!id && !room) return;
  document.dispatchEvent(new CustomEvent(ON_ROOM_CHANGE, { detail: { id, room } }));
}

export function dispatchOnRoomEnter({ id, room, canActivate = true } = {}) {
  if (!id && !room) return;
  document.dispatchEvent(new CustomEvent(ON_ROOM_ENTER, { detail: { id, room, canActivate } }));
}

export function dispatchOnRoomExit({ id, room } = {}) {
  if (!id && !room) return;
  document.dispatchEvent(new CustomEvent(ON_ROOM_EXIT, { detail: { id, room } }));
}

export function dispatchOnRoomActivate({ id, room } = {}) {
  if (!id && !room) return;
  document.dispatchEvent(new CustomEvent(ON_ROOM_ACTIVATE, { detail: { id, room } }));
}

/**
 * Category
 */
export const ON_CATEGORY_CHANGE = 'ON_CATEGORY_CHANGE';
export const ON_CATEGORY_CLOSE = 'ON_CATEGORY_CLOSE';

export function dispatchOnCategoryChange({ id, category } = {}) {
  if (!id && !category) return;
  document.dispatchEvent(new CustomEvent(ON_CATEGORY_CHANGE, { detail: { id, category } }));
}

export function dispatchOnCategoryClose({ id, room, category } = {}) {
  if (!id && !category) return;
  document.dispatchEvent(new CustomEvent(ON_CATEGORY_CLOSE, { detail: { id, room, category } }));
}

/**
 * Product
 */
export const ON_PRODUCT_CHANGE = 'ON_PRODUCT_CHANGE';
export const ON_PRODUCT_CLOSE = 'ON_PRODUCT_CLOSE';

export function dispatchOnProductChange({ id, category, product } = {}) {
  if (!id && !product) return;
  document.dispatchEvent(new CustomEvent(ON_PRODUCT_CHANGE, { detail: { id, category, product } }));
}

export function dispatchOnProductClose({ id, room, category, product } = {}) {
  if (!id && !product) return;
  document.dispatchEvent(new CustomEvent(ON_PRODUCT_CLOSE, { detail: { room, category, product } }));
}

/**
 * Close
 */
export const ON_CLOSE_BUTTON = 'ON_CLOSE_BUTTON';

export function dispatchOnCloseButtone() {
  document.dispatchEvent(new CustomEvent(ON_CLOSE_BUTTON));
}

// Displays

export const ON_DISPLAYS_CHANGE = 'ON_DISPLAYS_CHANGE';
export const ON_DISPLAYS_ACTIVATE = 'ON_DISPLAYS_ACTIVATE';

export function dispatchOnDisplaysChange({ category }) {
  if (!category) return;
  document.dispatchEvent(new CustomEvent(ON_DISPLAYS_CHANGE, { detail: { category } }));
}

export function dispatchOnDisplaysActivate() {
  document.dispatchEvent(new CustomEvent(ON_DISPLAYS_ACTIVATE));
}

// Chatbot
export const ON_CHATBOT_OPEN = 'ON_CHATBOT_OPEN';
export const ON_CHATBOT_CLOSE = 'ON_CHATBOT_CLOSE';

export function dispatchOnChatbotOpen() {
  document.dispatchEvent(new CustomEvent(ON_CHATBOT_OPEN));
}

export function dispatchOnChatbotClose() {
  document.dispatchEvent(new CustomEvent(ON_CHATBOT_CLOSE));
}

// 3D Model
export const ON_3D_MODEL_LOAD_COMPLETE = 'ON_3D_MODEL_LOAD_COMPLETE';

export function dispatchOn3dModelLoadComplete() {
  document.dispatchEvent(new CustomEvent(ON_3D_MODEL_LOAD_COMPLETE));
}
