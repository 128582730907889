import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        '--default-color': '#707070',
        '--color': '#FFB91D',
        '--hover-color': 'var(--default-color)',
        cursor: 'pointer',

        '&:hover': {
          '--color': 'var(--hover-color)',
          backgroundColor: 'unset',

          '&.swiper-button-disabled': {
            '--color': 'var(--default-color)',
          },
        },

        '& path': {
          fill: 'none',
          stroke: 'var(--color)',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2',
          transition: theme.transitions.create(['stroke']),
        },
      },
      left: {},
      right: {
        transform: 'rotate(180deg)',
      },
    };
  },
  { name: 'Arrow' },
);
