import clsx from 'clsx';
import { useEffect, useRef } from 'react';

import { dispatchOnDisplaysChange, dispatchOnDisplaysActivate, dispatchOnChatbotClose } from 'src/constants/event';
import { DISPLAYS, TEAM, AGENDA, RECEPTION, WELCOME, OVERLAY, REGISTER, VIDEO } from 'src/constants/rooms';
import { useDataContext } from 'src/context/DataContext';
import { CategoriesSlider } from '../CategoriesSlider/CategoriesSlider';
import { Menu } from '../Menu';
import { Model3d } from '../Model3d';
import { ButtonChat } from '../ButtonChat';
import { useStyles } from './Booth.style';
import { ButtonBack } from '../ButtonBack';
import { IframeContainer } from '../IframeContainer';
import { RegisterModal } from '../RegisterModal';
import { Video } from '../Video';
import { gsap } from 'gsap';

export function Booth({ className, type = 'left', ...props }) {
  const classes = useStyles();
  const { room, category, categories, product, setRoom, showProductIframe, setShowProductIframe } = useDataContext();
  const logoContainerRef = useRef();
  useEffect(() => console.clear(), []);

  useEffect(() => {
    if (!logoContainerRef.current) return;
    if (logoContainerRef.current.tl) logoContainerRef.current.tl.kill();

    const target = logoContainerRef.current.querySelector(`.${classes.logoBar}`);
    const duration = 0.5;
    const tl = gsap.timeline({ repeat: -1, ease: 'none' });
    tl.set(target, { marginLeft: 0, marginRight: 'auto' });
    tl.to(target, { width: '100%', duration });
    tl.set(target, { marginLeft: 'auto', marginRight: 0 });
    tl.to(target, { width: 0, duration });
    tl.to(target, { width: '100%', duration });
    tl.set(target, { marginLeft: 0, marginRight: 'auto' });
    tl.to(target, { width: 0, duration });

    logoContainerRef.current.tl = tl;

    function on3dModelLoadComplete() {
      tl.pause();

      const endTl = gsap.timeline();
      endTl.to(target, { width: '100%', duration });
      endTl.to(logoContainerRef.current, {
        autoAlpha: 0,
        duration,
        onComplete: () => {
          endTl.kill();
        },
      });
    }

    document.addEventListener('ON_3D_MODEL_LOAD_COMPLETE', on3dModelLoadComplete);

    () => {
      document.removeEventListener('ON_3D_MODEL_LOAD_COMPLETE', on3dModelLoadComplete);
    };
  }, []);

  const roomsWithIframes = [AGENDA, TEAM];
  return (
    <div className={classes.root}>
      {process.env.REACT_APP_HIDE_MODEL == '1' && room != REGISTER && <Model3d className={classes.model} />}
      {room == DISPLAYS && category && product && <CategoriesSlider />}
      {roomsWithIframes.includes(room) && category == OVERLAY && <IframeContainer />}
      {showProductIframe && <IframeContainer isProduct />}

      {(roomsWithIframes.includes(room) || room == RECEPTION) && (
        <ButtonBack
          onClick={() => {
            if (showProductIframe) {
              setShowProductIframe(false);
            } else {
              setRoom({ room: WELCOME });
            }
          }}
        />
      )}

      <Menu className={classes.bottomButton} />
      <ButtonChat className={clsx(classes.bottomButton, classes.buttonChat)} />

      {room == REGISTER && <RegisterModal />}
      {(room == VIDEO || (room == RECEPTION && category == OVERLAY)) && <Video />}

      {process.env.REACT_APP_DEBUG_MODEL === '1' && (
        <div style={{ position: 'fixed', zIndex: 999, top: 0 }}>
          <button onClick={() => setRoom({ room: VIDEO })}>VIDEO</button>
          <button onClick={() => setRoom({ room: AGENDA })}>AGENDA</button>
          <button onClick={() => setRoom({ room: TEAM })}>TEAM</button>
          <button onClick={() => setRoom({ room: RECEPTION })}>RECEPTION</button>
          <button onClick={() => setRoom({ room: REGISTER })}>REGISTER</button>
          {categories?.map(({ id, name, slug }) => (
            <button
              key={id}
              onClick={() => {
                if (room != DISPLAYS || category != slug) {
                  dispatchOnDisplaysChange({ category: slug });
                } else {
                  dispatchOnDisplaysActivate();
                }
              }}
            >
              Open Category - {name}
            </button>
          ))}
        </div>
      )}
      {room != REGISTER && (
        <div ref={logoContainerRef} className={classes.logoContainer}>
          <img className={classes.logo} src="https://parker-booth-2022.s3.ca-central-1.amazonaws.com/Loader_Dark_9ed0bd0525.svg" />
          <div className={classes.logoBarContainer}>
            <div className={classes.logoBar} />
          </div>
        </div>
      )}
    </div>
  );
}
