import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        '--padding-vertical': '10vh',
        '--padding-horizontal': '10vw',
        '--pagination-height': '1.5rem',
        '--navigation-spacing': '1rem',
        // bullet
        '--bullet-size': '0.25rem',
        '--bullet-padding': '0.25rem',
        '--bullet-spacing': '0.125rem',

        padding: 'var(--padding-vertical) var(--padding-horizontal)',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      background: {
        transition: theme.transitions.create('background-color', { easing: 'linear' }),
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // pointerEvents: 'none',
      },
      showBackground: {
        background: theme.palette.background.semitransparent,
      },
      blur: {
        filter: theme.blur,
        '& $bullet': {
          opacity: 0,
        },
      },
      swiper: {
        // width: '100%',
        width: 'min(150vh, 100%)',
        height: '100%',
        '& .swiper-pagination': {
          position: 'fixed',
          height: 'var(--pagination-height)',
          bottom: 'calc(var(--padding-vertical) / 2 - var(--pagination-height) / 2)',
        },
        '& .swiper-pagination-bullet-active': {},
      },
      slide: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      // Bullet
      bullet: {
        display: 'inline-block',
        cursor: 'pointer',
        padding: 'calc(var(--bullet-size) + var(--bullet-padding, --bullet-size))',
        marginLeft: 'var(--bullet-spacing, calc(var(--bullet-size) * 0.25))',
        position: 'relative',
        width: 'var(--bullet-size)',
        height: 'var(--bullet-size)',

        '&:after': {
          position: 'absolute',
          margin: 'var(--bullet-padding, --bullet-size)',
          padding: 'var(--bullet-size)',
          content: '""',
          borderRadius: '50%',
          backgroundColor: 'hsl(0, 0%, 100%, .2)',
          transition: theme.transitions.create('background-color'),
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        },
      },
      bulletActive: {
        '&:after': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      // Navigation
      navigation: {
        position: 'absolute',
        padding: 0,
        margin: 'var(--navigation-spacing)',
        width: 'calc(var(--padding-horizontal) - 6 * var(--navigation-spacing))',
        height: 'auto',
        transition: theme.transitions.create(['opacity']),
        opacity: 0,
      },
      navigationPrev: {
        left: 0,
      },
      navigationNext: {
        right: 0,
      },
      navigationVisible: {
        opacity: 1,
      },
    };
  },
  { name: 'ModalContainer' },
);
