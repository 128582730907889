import { Core } from './Core/Core.js';
import { Camera } from './Camera/Camera.js';
import { Renderer } from './Renderer/Renderer.js';
import { Controls } from './Controls/Controls.js';
import { Environment } from './Environment/Environment.js';
// import { ColorManager } from './Color/ColorManager.js';

import {RGBAFormat, Scene, sRGBEncoding, Vector2, WebGLMultisampleRenderTarget} from 'three';
import { PostRenderer } from './PostRenderer/PostRenderer.js';
import * as THREE from "three";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass.js";
import {UnrealBloomPass} from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer.js";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass.js";
import {GammaCorrectionShader} from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import {ToneMapShader} from "three/examples/jsm/shaders/ToneMapShader";

class Engine {
  constructor(container) {
    this.container = container;
    this.camera = new Camera(container);
    this.renderer = new Renderer(container, true);
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		//this.renderer.antialias = true;
    //this.renderer.autoClear = false;

    this.scene = new Scene();
    this.controls = new Controls(this.camera, container);
    this.environment = new Environment(this.scene, this.renderer);

    this.core = new Core(container);

    // this array contains objects with an update
    // method that will run once per frame
    this.updatables = [this.controls];

    this.resizeables = [this.camera, this.renderer];
    this.prerenderers = [];
    this.setupResizeHandler();
  }

  setupBloomEffect() {
		const glowShader = {
			fragmentShader: `
				uniform sampler2D baseTexture;
				uniform sampler2D bloomTexture;
				varying vec2 vUv;

				void main() {
					gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
				}`,
			vertexShader:`
				varying vec2 vUv;

				void main() {
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}`
		}
		const glowParams = {
			exposure: 0.5,
			bloomStrength: 1,
			bloomThreshold: 0,
			bloomRadius: 0
		};

  	this.renderScene = new RenderPass(this.scene, this.camera);
  	this.bloomPass = new UnrealBloomPass( new Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
		this.bloomPass.threshold = glowParams.bloomThreshold;
		this.bloomPass.strength = glowParams.bloomStrength;
		this.bloomPass.radius = glowParams.bloomRadius;

		this.bloomComposer = new EffectComposer( this.renderer );
		this.bloomComposer.renderToScreen = false;

		this.bloomComposer.addPass( this.renderScene );
		this.bloomComposer.addPass( this.bloomPass );


		const finalPass = new ShaderPass(
			new THREE.ShaderMaterial( {
				uniforms: {
					baseTexture: { value: null },
					bloomTexture: { value: this.bloomComposer.renderTarget2.texture }
				},
				...glowShader,
				defines: {}
			} ), "baseTexture"
		);
		finalPass.needsSwap = true;

    let target
    if (this.renderer.capabilities.isWebGL2) {
      target = new WebGLMultisampleRenderTarget(window.innerWidth, window.innerHeight, {
        format: RGBAFormat,
        //encoding: sRGBEncoding,
      })
      target.samples = 4
    }

    this.gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
    this.toneMappingPass = new ShaderPass(ToneMapShader);

		this.finalComposer = new EffectComposer( this.renderer, target);
		this.finalComposer.addPass( this.renderScene );
    //this.finalComposer.addPass( this.toneMappingPass );
    this.finalComposer.addPass( this.gammaCorrectionPass );
		this.finalComposer.addPass( finalPass );

		this.core.setupBloomEffectsRenderer( this.bloomComposer, this.finalComposer);
		this.resizeables.push(this.bloomComposer, this.finalComposer);
	}

  setupResizeHandler() {
    window.addEventListener('resize', () => {
      for (const object of this.resizeables) {
        if (typeof object.resize === 'function') {
          object.resize(this.container);
        }
      }
    });
  }

  setControls(controls) {
    if (this.controls.dispose) {
      this.controls.dispose();
    }

    const index = this.updatables.indexOf(this.controls);
    if (index !== -1) this.updatables[index] = controls;

    this.controls = controls;
  }

  addRenderable(object, updatable = false) {
    this.scene.add(object);

    if (updatable) {
      // const name = object.name !== '' ? object.name : object.uuid;
      // this.updatables[name] = object;
      this.updatables.push(object);
    }
  }

  removeRenderable(object) {
    this.scene.remove(object);

    this.updatables = this.updatables.filter(item => item !== object);
  }

  addPrerender(object) {
    this.prerenderers.push(object);
  }

  removePrerender(object) {
    this.prerenderers = this.prerenderers.filter(item => item !== object);
  }

  // Automatically switch to post renderer when effects are added
  // To force this to happen early,
  // e.g. to prevent shader recompilation when adding passes later,
  // pass an empty array to this function
  addEffects(effects) {
    if (!(this.renderer instanceof PostRenderer)) {
      this.resizeables = this.resizeables.filter(
        item => item !== this.renderer,
      );

      this.renderer = new PostRenderer(
        new Renderer(this.container, true),
        this.scene,
        this.camera,
      );

      this.resizeables.push(this.renderer);
    }
    for (const effect of effects) {
      this.renderer.addPass(effect);
    }
  }

  start() {
    this.core.start(
      this.renderer,
      this.scene,
      this.camera,
      this.updatables,
      this.prerenderers
    );
  }

  stop() {
    this.core.stop(this.renderer);
  }

  getControls() {
    return this.controls.controls;
  }
}

export { Engine };
