import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        '--background-color': 'transparent',
        '--button-color': theme.palette.secondary.main,
        '--orientation': '0deg',
        '--button-color': 'rgba(255, 255, 255, .8)',
        '--button-active-color': theme.palette.secondary.main,
        '--vertical-margin': '1rem',
        '--height': '5.5rem',

        display: 'flex',
        backgroundColor: 'var(--background-color)',
        transition: theme.transitions.create(['background-color']),
      },
      background: {
        '--background-color': theme.palette.secondary.main,
        '--button-color': '#000',
        '--button-active-color': '##000',
      },
      open: {
        '& $openButton': {
          transform: 'translateX(-100%)',
        },
        '& $navButton': {
          transform: 'translateX(0)',
          opacity: 1,
          pointerEvents: 'auto',
        },
      },
      button: {
        padding: 0,
        transition: theme.transitions.create(['background-color']),
      },
      openButton: {
        backgroundColor: theme.palette.secondary.main,
        transition: theme.transitions.create(['transform']),
        height: 'var(--height)',
        alignItems: 'flex-start',
        color: '#000',

        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      },
      openIcon: {
        marginTop: 'var(--vertical-margin)',
        marginLeft: '.25rem',
        marginRight: '.25rem',
        width: '1.75rem',
        height: '1.25rem',
      },
      options: {
        display: 'flex',
        height: 'var(--height)',
        paddingRight: '.5rem',
      },
      hasButtonClose: {
        marginLeft: '-3rem',
      },
      navButton: {
        marginTop: 'var(--vertical-margin)',
        marginBottom: 'var(--vertical-margin)',
        display: 'flex',
        flexDirection: 'column',
        transition: theme.transitions.create(['transform', 'opacity', 'color']),
        transform: 'translateX(-200%)',
        opacity: 0,
        pointerEvents: 'none',

        '& $icon': {
          color: 'var(--button-color)',
        },
        '& $buttonDescription': {
          color: 'var(--button-color)',
          opacity: 0,
        },

        '&:hover $icon': {
          '--button-color': 'var(--button-active-color)',
        },

        '&:hover $buttonDescription': {
          '--button-color': 'var(--button-active-color)',
          opacity: 1,
        },
      },
      selectedNavButton: {
        '--button-color': 'var(--button-active-color)',

        '& $buttonDescription': {
          opacity: 1,
        },
      },
      closeButton: {
        justifyContent: 'flex-start',

        '& $icon': {
          height: '1.8rem',
          marginTop: '0.1rem',
        },
      },
      icon: {
        height: '2.125rem',
        width: 'auto',
        fill: 'var(--button--color)',
        transition: theme.transitions.create(['color', 'opacity']),
        marginBottom: '.5rem',
      },
      buttonDescription: {
        opacity: 0,
        transition: theme.transitions.create(['color', 'opacity']),
        fontSize: '0.75rem',
      },
    };
  },
  { name: 'Menu' },
);
